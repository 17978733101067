<template>
  <div>
    <el-autocomplete class="search" :title="language=='CN'?'按ENTER直接搜索':'Press ENTER for search'" :placeholder="language=='CN'?'请输入产品名称':'Product Name'" :fetch-suggestions="fetchSuggestions" :trigger-on-focus="false" @select="handleSearch" v-model="keyword" prefix-icon="el-icon-search" @keydown.enter.native="handleSearch"></el-autocomplete>
  </div>
</template>

<script>
import {getAction} from "@/api/manage";

export default {
  name: "Search",
  data() {
    return {
      keyword:""
    }
  },
  computed:{
    language(){
      return this.$store.getters.language
    }
  },
  methods: {
    handleSearch(){
      console.log("keyword",this.keyword)
      this.$router.push("/search_product?keyword="+this.keyword)
    },
    fetchSuggestions(keyword,callback){
      getAction("/api/portal/product/list",{
        hasDisplay:1,
        name:`*${keyword?keyword:""}*`
      }).then(res=>{
        callback(res.result.records.map(i=>({value:i.name})))
      })
      // let timeout = setTimeout(()=>{
      //   callback([{
      //     value:"123456"
      //   }])
      // },3000)
    },
  },
}
</script>

<style lang="less" scoped>
.search{
  //width: 300px;
  /deep/ .el-input__inner{
    height: 38px;
    border-radius: 19px;
  }
}
</style>
