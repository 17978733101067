<template>
  <div>
      <el-image class="el-img" fit="contain" v-if="$store.state.config.sysCompanyLogo" @click="$router.push('/')" :src="getFileAccessHttpUrl($store.state.config.sysCompanyLogo)"></el-image>
  </div>
</template>

<script>
import {getFileAccessHttpUrl} from "@/api/manage";

export default {
  name: "Logo",
  data() {
    return {}
  },
  methods: {getFileAccessHttpUrl},
}
</script>

<style lang="less" scoped>
.el-img {
	height:44px;
	width:250px;
	cursor: pointer
}

</style>
