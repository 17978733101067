import Axios from 'axios'
import {Message, Notification} from 'element-ui';

console.log(process.env.VUE_APP_API_BASE_URL)
const myAxios = Axios.create({
 baseURL:process.env.VUE_APP_API_BASE_URL
})
const err = (error) => {
    if (error.response) {
        let data = error.response.data
        switch (error.response.status) {
            case 403:
                Notification({type:"error" ,title: '系统提示', message: '拒绝访问',duration: 4000})
                break
            case 500:
                let type=error.response.request.responseType;
                Notification({type:"error" ,title: '系统提示', message: '服务器端错误',duration: 4000})
                break
            case 404:
                Notification({type:"error" ,title: '系统提示', message: '很抱歉，资源未找到!',duration: 4000})
                break
            case 504:
                Notification({type:"error" ,title: '系统提示', message: '网络超时',duration: 4000})
                break
            default:
                Notification({type:"error" ,title: '系统提示', message: data.message,duration: 4000})
                break
        }
    } else if (error.message) {
        if (error.message.includes('timeout')) {
            Notification({type:"error" ,title: '系统提示', message: "网络超时",duration: 4000})
        } else {
            Notification({type:"error" ,title: '系统提示', message: error.message,duration: 4000})
        }
    }
    return Promise.reject(error)
};

myAxios.interceptors.response.use(function (response) {
    console.log("response",response)
    if(!response.data.success){
        // Message.warning(response.data.message)
    }
    return response.data;
}, err);
const axios = function (config){
    return myAxios({
        ...config
    })
}
export  { axios }
