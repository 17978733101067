<template>
  <div>
    <template v-if="getImagesList.length>1">
      <el-carousel :height="height" class="carousel" :autoplay = "true">
        <el-carousel-item v-for="item in getImagesList" :key="item">
          <img :src="item" height="100%" width="100%" style="object-fit: cover" alt="" srcset="">
        </el-carousel-item>
      </el-carousel>
    </template>
    <template v-if="getImagesList.length==1">
      <!--      此处放单张图片-->
      <div :style="{
        height:height,
        background:'white'
      }">
        <img :src="getImagesList[0]" style="width: 100%;height: 100%;object-fit: cover" alt="">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "HeaderCarouselMini",
  props: {
    height: {
      default: "150px"
    }
  },
  data() {
    return {
    }
  },
  watch: {
    $route(n) {
      // 路由切换时切换轮播图
      // console.log(n)
    }
  },
  computed:{
    getImagesList(){
      if(this.$route.meta.listPicture){
        return this.$route.meta.listPicture.map(i=>i.thumb_download)
      }
      return []
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item {
  background: linear-gradient(0deg, #464612, #679191);
}

/deep/ .el-carousel__indicators {
  padding: 0 10px;
  margin-bottom: 24px;
  border-radius: 15px;
  font-size: 0;
  background: rgba(50, 51, 51, 0.65);
  .el-carousel__indicator {
    padding: 6px;
    font-size: 0;
    .el-carousel__button {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 8px;
    }


    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  .is-active{
    .el-carousel__button{
      width: 16px !important;
    }
  }
}

</style>
