import router from "@/router";
import {Message} from "element-ui";
export  {
    routeGenerator,
    getRoutePath
}
const routesFilter =function (routeData,parentsRoutePath){
    console.log("routeData",routeData,parentsRoutePath)
    let route = {
    }
    route.component = getRouteComponent(!parentsRoutePath&&routeData.children?-1:routeData.children?0:routeData.type)
    // route.component = getRouteComponent(routeData.type)
    // route.path = (parentsRoutePath?parentsRoutePath:"") + "/"+routeData.route
    route.path =routeData.type==10?encodeURI(routeData.route):"/"+encodeURI(routeData.route)
    route.name = routeData.id+"_"+routeData.nameEn
    route.meta = {
        // carousel:routeData.listPicture,
        ...routeData
    }
    console.log("route.meta",route.meta,route.path)
    if(routeData.children){
        route.children = routeData.children.map(i=>routesFilter(i,route.path))
        //添加每个页面的重定向
        console.log("stm",routeData,(parentsRoutePath?parentsRoutePath:"")+"/",route.path)
        if(route.children.find(i=>i.meta.type!==10)){
            route.children.unshift({
                path:route.path+"/",
                meta:{
                    ...routeData
                },
                redirect:route.children.find(i=>i.meta.type!==10).path
            })
        }
    }
    return route
    // return addRoute
}
const getRouteComponent =function (type){
    switch (type){
        case -1:
            return ()=>import("@/components/layouts/others")
        case 0:
            return ()=>import("@/components/layouts/RouteView.vue")
        case 1:
            return ()=>import("@/views/home")
        case 2:
            return ()=>import("@/views/products")
        case 3:
            return ()=>import("@/views/currency")
        case 4:
            return ()=>import("@/views/research")
        case 5:
            return ()=>import("@/views/application")
        case 6:
            return ()=>import("@/views/news")
        case 7:
            return ()=>import("@/views/honor")
        case 8:
            return ()=>import("@/views/contactUs")
        case 9:
            return ()=>import("@/views/history")
        default:
            return  ()=>import("@/components/layouts/others/modules/404.vue")
    }
}

const routeGenerator = function (routesData){
    console.log("route",routesData)
    if(!routesData.length){
        Message.warning("未设置菜单！")
        return
    }
    let routes = routesData.map(i=>routesFilter(i))
    // 添加首页的重定向
    routes.unshift({
        path:"/",
        meta:{...routesData.find(i=>i.type==1)},
        redirect:"/"+routesData.find(i=>i.type==1).route
    })
    console.log("routes",routes)
    return routes
}
const getRoutePath=function(id,return_all){
    let arr = router.getRoutes()
    if(return_all){
        console.log(arr,id)
        return arr.find(i=>{
            return i.meta.id==id||i.name?.split("_")[0]==id
        })?.meta||{}
    }
    if(arr.find(i=>i.meta.id==id)){
        return arr.find(i=>i.meta.id==id).path
    }
    return ""
}

//防抖
export const Debounce = function (fn, t=2000) {
    let delay = t || 200;
    let timer = null;
    return function () {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay);
    }
}
//节流
export const Throttle = function (fn, delay = 2000) {
    let canRun = true; // 通过闭包保存一个标记
    return function () {
        if (!canRun) return;//在delay时间内，直接返回，不执行fn
        canRun = false;
        fn.apply(this, arguments);
        setTimeout(() => {
            canRun = true;//直到执行完fn,也就是delay时间后，打开开关，可以执行下一个fn
        }, delay);
    };
}

