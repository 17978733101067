<template>
  <div>
      <el-image fit="contain" :src="getFileAccessHttpUrl($store.state.config.sysCompanyLogo)" @click="$router.push('/')"></el-image>
  </div>
</template>

<script>
import {getFileAccessHttpUrl} from "@/api/manage";

export default {
  name: "LogoMini",
  data() {
    return {}
  },
  methods: {getFileAccessHttpUrl},
}
</script>

<style lang="less" scoped>
.el-image{
  height: 23.23px;
  width: 132px;
  font-size: 0;
}
</style>
