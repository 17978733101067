import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseRoutes: [
      {
        path: "/search_product",
        component: () => import("@/views/search_product"),
        name: "search_product",
        meta: {
          name: "产品搜索",
          nameEn: "Product Search"
        },
      },
      {
        path: "/product",
        component: () => import("@/views/products"),
        name: "product",
        meta: {
          name: "产品详情",
          nameEn: "Product Detail"
        },
      },
      {
        path: "/Press",
        component: () => import("@/views/Press"),
        name: "Press",
        meta: {
          name: "新闻详情",
          nameEn: "Press Detail"
        },
      },
      {
        path: "/404",
        component: () => import("@/components/layouts/others/modules/404.vue"),
        name: "404",
        meta: {
          name: "404",
        },
      }
    ],
    AdditionalRoutes: [
    ],
    language: "CN",
    config: {},
    businessConfig: {},
    infoConfig: {},
  },
  getters: {
    getMenus(state) {
      let arr = [...state.AdditionalRoutes]
      return arr
    },
    language(state) {
      return state.language
    },
    businessConfig(state) {
      return state.businessConfig
    },
    infoConfig(state) {
      return state.infoConfig
    }
  },
  mutations: {
    SET_ROUTES(state, routes) {
      state.AdditionalRoutes = routes

      // {
      //   meta:state.AdditionalRoutes.find(i=>i.meta.type==1)?.meta,
      //   ...i,
      // }
      // var a= state.baseRoutes.map(i => {
      //   console.log('state.AdditionalRoutes1',state.AdditionalRoutes.find(i => i.meta.type == 1)?.meta);
      //   console.log('state.AdditionalRoutes',state.AdditionalRoutes);
      //   return {
      //     meta: state.AdditionalRoutes.find(i => i.meta.type == 1)?.meta,
      //     ...i,
      //   }
      // })
      var a = state.baseRoutes.map(i => {
        return {
          ...i,
          meta: {
            ...i.meta,
            listPicture: state.AdditionalRoutes.find(i => i.meta.type == 1)?.meta?.listPicture
          }
        }
      })
      console.log('state.baseRoutes ', a);
      let arr = [...a, ...state.AdditionalRoutes]
      arr.forEach(i => {
        router.addRoute(i)
      })
    },
    SET_LANGUAGE(state, language) {
      state.language = language
    },
    SET_CONFIG(state, config) {
      state.config = config
    },
    SET_BUSINESS_CONFIG(state, config) {
      state.businessConfig = config
    },
    SET_INFO_CONFIG(state, config) {
      state.infoConfig = config
    }
  },
  actions: {
    addRoutes({ commit }, routes) {
      commit('SET_ROUTES', routes)
    },
    ChangeLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    getConfig({ commit }, config) {
      commit("SET_CONFIG", config)
    },
    getBusinessConfig({ commit }, config) {
      commit("SET_BUSINESS_CONFIG", config)
    },
    getInfoConfig({ commit }, config) {
      commit("SET_INFO_CONFIG", config)
    }
  },
  modules: {
  }
})
