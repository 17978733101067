import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from "@/store";
import {getAction} from "@/api/manage";
import {routeGenerator} from "@/utils/utils";
import store from "@/store";

Vue.use(VueRouter)

const routes = Store.getters.getRoutes

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach(async (to, from, next) => {
    console.log("router.to",to)
    // document.documentElement.scrollTop = 0
    if (!router.getRoutes().length) { // 检查路由是否已经加载，防止刷新后页面空白的问题
        // 请求接口获取路由配置
        let timeout = setTimeout(()=>{
            clearTimeout(timeout)
            next({path:to.fullPath,replace:true})
        },100)
    } else {
        if(to.matched.length==0){
            next("/404")
        }else {
            next() // 已加载路由，继续导航
        }
    }
})
export default router
